{
  "baseFooter": {
    "rights": "Tots els drets reservats"
  },
  "baseHeader": {
    "menu": {
      "account": "El teu compte",
      "language": "Idioma: {currentLanguage}",
      "logout": "Tancar sessió",
      "manage_following": "Gestiona el seguiment",
      "my_clips_and_highlights": "Els meus clips/destacats",
      "select_language": "Escolliu l'idioma",
      "selected_videos": "Vídeos seleccionats"
    }
  },
  "button": {
    "back": "Retrocedir",
    "buy_now": "Compra ara",
    "cancel": "Cancel·lar",
    "clear_all": "Esborra-ho tot",
    "confirm": "Confirmeu",
    "delete": "Suprimeix",
    "download": "Descarregar",
    "edit": "Edita",
    "enter_another_card": "Introduïu una altra targeta",
    "enter_another_code": "Introduïu un altre codi",
    "fullscreen_enter": "Entra a pantalla completa",
    "fullscreen_exit": "Sortir de pantalla completa",
    "no": "No",
    "refresh": "Actualització",
    "remove": "Eliminar",
    "submit_code": "Envia el codi",
    "toggle_dark": "Canvia el mode fosc",
    "toggle_langs": "Canviar idiomes",
    "try_again": "Torna-ho a provar",
    "go_to_homepage": "Vés a la pàgina d'inici",
    "slide_back": "Diapositiva anterior",
    "slide_forward": "Diapositiva següent",
    "exit": "Sortida",
    "full": "Complet",
    "regular": "Regular",
    "rename": "Canvia el nom",
    "get": "Aconseguir"
  },
  "common": {
    "data_not_found": "No s'han trobat dades o el servidor no respon. \nSiusplau, intenta-ho més tard.",
    "empty_list": "La llista està buida. \nTorna després.",
    "empty_search": "No s'han trobat resultats amb un títol \"{searchValue}\".",
    "loading": "Carregant...",
    "loading_failed": "No s'ha pogut carregar",
    "empty_events_list": "Encara no hi ha esdeveniments",
    "get_mobile_app": "Obteniu la millor experiència \n{appName}"
  },
  "countries": {
    "AD": "Andorra",
    "AE": "Emirats Àrabs Units",
    "AF": "Afganistan",
    "AG": "Antigua i Barbuda",
    "AI": "Anguilla",
    "AL": "Albània",
    "AM": "Armènia",
    "AO": "Angola",
    "AR": "Argentina",
    "AS": "Samoa Americana",
    "AT": "Àustria",
    "AU": "Austràlia",
    "AW": "Aruba",
    "AZ": "Azerbaidjan",
    "BA": "Bòsnia i Hercegovina",
    "BB": "Barbados",
    "BD": "Bangla Desh",
    "BE": "Bèlgica",
    "BF": "Burkina Faso",
    "BG": "Bulgària",
    "BH": "Bahrain",
    "BI": "Burundi",
    "BJ": "Benín",
    "BM": "Bermudes",
    "BN": "Brunei",
    "BO": "Bolívia",
    "BR": "Brasil",
    "BS": "Bahames",
    "BT": "Butan",
    "BW": "Botswana",
    "BY": "Bielorússia",
    "BZ": "Belize",
    "CA": "Canadà",
    "CF": "República Centreafricana",
    "CG": "Congo",
    "CH": "Suïssa",
    "CK": "Illes Cook",
    "CL": "Xile",
    "CM": "Camerun",
    "CN": "Xina",
    "CO": "Colòmbia",
    "CR": "Costa Rica",
    "CU": "Cuba",
    "CV": "Cap Verd",
    "CW": "Curaçao",
    "CX": "Illa de Nadal",
    "CY": "Xipre",
    "DE": "Alemanya",
    "DJ": "Djibouti",
    "DK": "Dinamarca",
    "DM": "Dominica",
    "DO": "República Dominicana",
    "DZ": "Algèria",
    "EC": "Equador",
    "EE": "Estònia",
    "EG": "Egipte",
    "ER": "Eritrea",
    "ES": "Espanya",
    "ET": "Etiòpia",
    "FI": "Finlàndia",
    "FJ": "Fiji",
    "FK": "Illes Malvines",
    "FM": "Micronèsia",
    "FO": "Illes Faroe",
    "FR": "França",
    "GA": "Gabon",
    "GD": "Granada",
    "GE": "Geòrgia",
    "GF": "Guaiana Francesa",
    "GH": "Ghana",
    "GI": "Gibraltar",
    "GL": "Groenlàndia",
    "GM": "Gàmbia",
    "GN": "Guinea",
    "GP": "Guadalupe",
    "GQ": "Guinea Equatorial",
    "GR": "Grècia",
    "GS": "Geòrgia del Sud i les illes Sandwich del Sud",
    "GT": "Guatemala",
    "GU": "Guam",
    "GW": "Guinea Bissau",
    "GY": "Guyana",
    "HK": "RAE de Hong Kong Xina",
    "HN": "Hondures",
    "HR": "Croàcia",
    "HT": "Haití",
    "HU": "Hongria",
    "ID": "Indonèsia",
    "IE": "Irlanda",
    "IL": "Israel",
    "IN": "Índia",
    "IO": "Territori Britànic de l'Oceà Índic",
    "IQ": "Iraq",
    "IR": "Iran",
    "IS": "Islàndia",
    "IT": "Itàlia",
    "JM": "Jamaica",
    "JO": "Jordània",
    "JP": "Japó",
    "KE": "Kenya",
    "KG": "Kirguizistan",
    "KH": "Cambodja",
    "KI": "Kiribati",
    "KM": "Comores",
    "KP": "Corea del Nord",
    "KR": "Corea del Sud",
    "KW": "Kuwait",
    "KY": "Illes Caiman",
    "KZ": "Kazakhstan",
    "LA": "Laos",
    "LB": "Líban",
    "LI": "Liechtenstein",
    "LK": "Sri Lanka",
    "LR": "Libèria",
    "LS": "Lesotho",
    "LT": "Lituània",
    "LU": "Luxemburg",
    "LV": "Letònia",
    "LY": "Líbia",
    "MA": "el Marroc",
    "MC": "Mònaco",
    "MD": "Moldàvia",
    "ME": "Montenegro",
    "MG": "Madagascar",
    "MH": "Illes Marshall",
    "ML": "Mali",
    "MM": "Myanmar",
    "MN": "Mongòlia",
    "MO": "RAE de Macau Xina",
    "MP": "Illes Mariannes del Nord",
    "MQ": "Martinica",
    "MR": "Mauritània",
    "MS": "Montserrat",
    "MT": "Malta",
    "MU": "Maurici",
    "MV": "Maldives",
    "MW": "Malawi",
    "MX": "Mèxic",
    "MY": "Malàisia",
    "NA": "Namíbia",
    "NC": "Nova Caledònia",
    "NE": "Níger",
    "NF": "Illa Norfolk",
    "NG": "Nigèria",
    "NI": "Nicaragua",
    "NL": "Països Baixos",
    "NO": "Noruega",
    "NP": "Nepal",
    "NR": "Nauru",
    "NU": "Niue",
    "NZ": "Nova Zelanda",
    "OM": "Oman",
    "PA": "Panamà",
    "PE": "Perú",
    "PF": "Polinèsia Francesa",
    "PG": "Papua Nova Guinea",
    "PH": "Filipines",
    "PK": "Pakistan",
    "PL": "Polònia",
    "PR": "Puerto Rico",
    "PS": "Territori Palestí",
    "PT": "Portugal",
    "PW": "Palau",
    "PY": "Paraguai",
    "QA": "Qatar",
    "RE": "Reunió",
    "RO": "Romania",
    "RS": "Sèrbia",
    "RU": "Rússia",
    "RW": "Ruanda",
    "SA": "Aràbia Saudita",
    "SB": "Illes Salomó",
    "SC": "Seychelles",
    "SD": "Sudan",
    "SE": "Suècia",
    "SG": "Singapur",
    "SI": "Eslovènia",
    "SK": "Eslovàquia",
    "SL": "Sierra Leone",
    "SM": "San Marino",
    "SN": "Senegal",
    "SV": "El Salvador",
    "SY": "Síria",
    "TC": "Illes Turks i Caicos",
    "TD": "Txad",
    "TG": "Anar",
    "TH": "Tailàndia",
    "TJ": "Tadjikistan",
    "TK": "Tokelau",
    "TM": "Turkmenistan",
    "TN": "Tunísia",
    "TO": "Tonga",
    "TR": "Turquia",
    "TT": "Trinitat i Tobago",
    "TV": "Tuvalu",
    "TW": "Taiwan",
    "TZ": "Tanzània",
    "UA": "Ucraïna",
    "UG": "Uganda",
    "GB": "Regne Unit",
    "US": "Estats Units",
    "UY": "Uruguai",
    "UZ": "Uzbekistan",
    "VE": "Veneçuela",
    "VG": "Illes Verges Britàniques",
    "VI": "Illes Verges dels EUA",
    "VN": "Vietnam",
    "VU": "Vanuatu",
    "WF": "Wallis i Futuna",
    "WS": "Samoa",
    "YE": "Iemen",
    "YT": "Mayotte",
    "ZA": "Sud-Àfrica",
    "ZM": "Zàmbia",
    "CD": "Congo, Dem. \nRep. de (Zaire)",
    "ZW": "Zimbabwe",
    "AX": "Illes Åland",
    "BQ": "Països Baixos del Carib",
    "EA": "Ceuta i Melilla",
    "EH": "Sàhara Occidental",
    "GG": "Guernsey",
    "IC": "Illes Canàries",
    "IM": "illa de l'home",
    "JE": "Jersey",
    "MZ": "Moçambic",
    "PN": "Illes Pitcairn",
    "SO": "Somàlia",
    "SS": "Sudan del Sud",
    "SX": "Sint Maarten",
    "TA": "Tristan da Cunha",
    "TF": "Territoris del Sud francesos",
    "VA": "La ciutat del Vatica",
    "XK": "Kosovo",
    "AC": "Illa de l'Ascensió",
    "CZ": "Txeca",
    "CC": "Illes Cocos (Keeling).",
    "TL": "Timor-Leste",
    "CI": "Costa d'Ivori",
    "MK": "Macedònia del Nord",
    "SR": "Surinam",
    "SZ": "Eswatini",
    "DG": "Diego Garcia"
  },
  "footer_links": {
    "about_us": "Sobre nosaltres",
    "custom": "{custom}",
    "faq": "Preguntes freqüents",
    "privacy_policy": "Privadesa",
    "terms_of_service": "Termes"
  },
  "labels": {
    "access_code": "Introduïu el codi d'accés",
    "access_time": "Tindràs accés a",
    "active": "Actiu",
    "all": "Tots",
    "all_results": "Tots els resultats",
    "amount": "Import",
    "anytime": "En qualsevol moment",
    "bookmark_add": "Afegeix als seleccionats",
    "bookmark_remove": "Elimina els seleccionats",
    "change_product": "Canviar producte",
    "clip": "Clip | \nClips",
    "commerce_category_labels": {
      "free": "Gratuït",
      "member": "Membre",
      "ppv": "Pay per view",
      "premium": "Premium",
      "rental": "Lloguer",
      "subscription": "Subscripció"
    },
    "lock_event_message": {
      "member": "Inicia sessió per veure el partit",
      "geo_blocking": "Ho sentim, però el vídeo que estàs provant de reproduir està restringit a la teva regió",
      "event_restricted": "Aquest esdeveniment està restringit a la teva ubicació",
      "premium": "Subscriu-te a Watch",
      "subscription": ""
    },
    "confirm_new_password": "Confirmar nova contrasenya",
    "continue": "Continua",
    "continue_with": "Continua amb {provider}",
    "country": "País",
    "create_highlight": "Crea un destacat",
    "dark": "Fosc",
    "date": "Data",
    "date_added": "Data afegida:",
    "days": "Dies | \ndia | \ndies",
    "email": "Correu electrònic",
    "enter_new_password": "Introduïu una nova contrasenya",
    "event_date": "Data de l'esdeveniment",
    "events": "Esdeveniments",
    "follow": "Segueix",
    "following": "Seguint",
    "forgot_password": "Has oblidat la contrasenya",
    "full_access": "Accés complet",
    "full_name": "Nom complet",
    "game_highlights": "Punts destacats del joc",
    "game_videos": "Vídeos del joc",
    "go_to_account": "Vés al compte",
    "go_to_event": "Anar a l'esdeveniment",
    "go_to_main_page": "Vés a la pàgina principal",
    "highlights": "Destacat | Destacats",
    "hours": "Hores | \nhores | \nhores",
    "inactive": "Inactiu",
    "latest": "Últim",
    "latest_events": "Últims esdeveniments",
    "latest_highlights": "Últims moments destacats",
    "light": "Llum",
    "limited_access": "Accés limitat",
    "live": "En directe",
    "load_more": "Carrega més",
    "main": "Principal",
    "minutes": "Minuts | \nminut | \nminuts",
    "modal_labels": {
      "delete_clip": "Esteu segur que voleu eliminar aquest clip?",
      "delete_highlight": "Esteu segur que voleu eliminar aquest destacat?",
      "delete_profile": "Esteu segur que voleu suprimir el vostre perfil?",
      "delete_tag": "Esteu segur que voleu eliminar aquestes etiquetes? \n| \nEsteu segur que voleu eliminar aquesta etiqueta?"
    },
    "months": "Mesos | \nmes | \nmesos",
    "name": "Nom",
    "newest": "El més nou",
    "next": "Pròxim",
    "oldest": "El més antic",
    "or": "o",
    "page_not_found": "Pàgina no trobada",
    "password": "Contrasenya",
    "password_new": "Nova contrasenya",
    "password_old": "Contrasenya antiga",
    "password_repeat": "Repeteix la contrassenya",
    "past_30_days": "Darrers 30 dies",
    "past_7_days": "Darrers 7 dies",
    "past_90_days": "Darrers 90 dies",
    "phone": "Telèfon",
    "player_highlights": "Aspectes destacats del jugador",
    "policy": "Política de privacitat",
    "powered_by": "Impulsat per",
    "ppv_price": "Mira des de {price}",
    "ppv_tickets": "Entrades PPV | \nEntrada PPV",
    "purchase": "Compra",
    "release_date": "Data de publicació",
    "save": "Desa",
    "search": "Cerca",
    "search_input_placeholder": "Cerca escoles, clubs, lligues, etc.",
    "seconds": "Segons | \nsegon | \nsegons",
    "see_all": "Veure-ho tot >",
    "share": "Compartir",
    "sign_in": "Inicia sessió",
    "sign_up": "Registra't",
    "skip": "Omet",
    "sport_type": "Tipus esportiu",
    "statistics": "Estadístiques",
    "submit": "Presentar",
    "subscriptions": "Subscripcions",
    "tag": "Etiqueta | \nEtiquetes",
    "terms": "Termes del servei",
    "today": "Avui",
    "total": "Total",
    "type": "Tipus",
    "upcoming": "Properament",
    "update": "Actualització",
    "update_card": "Actualitzar la targeta",
    "views": "sense vistes | \n{count} visualització | \n{count} visualitzacions",
    "watch_full_game": "Veure el joc complet",
    "weeks": "Setmanes | \nsetmana | \nsetmanes",
    "years": "Anys | \ncurs | \nanys",
    "yesterday": "Ahir",
    "your_clips_highlight": "Els teus clips/destacats",
    "my_club": "El meu club",
    "select_your_club": "Seleccioneu el vostre club",
    "add_to_selected": "Afegeix als seleccionats",
    "remove_from_selected": "Elimina dels seleccionats",
    "password_not_match": "Les contrasenyes no coincideixen",
    "search_by_sport_type": "Cerca per tipus d'esport",
    "videos": "Vídeos",
    "category": "Categoria",
    "reset_password": "Introduïu la vostra nova contrasenya",
    "min_8_characters": "Mínim 8 caràcters",
    "league": "Lliga",
    "here": "Aquí",
    "suggested": "Suggerit",
    "enter_old_password": "Introduïu la contrasenya antiga",
    "less": "Menys",
    "more": "Més",
    "durationRenew": "Durada: es renovarà {duration} {units} després de la compra | \nDurada: es renovarà el {duration}",
    "duration": "Durada: caduca {duration} {units} després de la compra | \nDurada: caduca {duration}",
    "team": "Equip",
    "team_1_logo": "Logotip de l'equip 1",
    "team_2_logo": "Logotip de l'equip 2",
    "stats": "Estadístiques",
    "gameType_labels": {
      "game": "Joc",
      "other": "Altres",
      "practice": "Pràctica",
      "training": "formació"
    },
    "fullName": "Nom complet",
    "archive": "Arxiu",
    "panoramic_mode": "Esteu en mode panoràmic",
    "postal_code": "Codi Postal",
    "search_event_league_team": "Cerca un esdeveniment, lliga o equip",
    "back_to_site": "Tornar al lloc",
    "done": "Fet",
    "search_teams": "Cerca equips",
    "game_recap": "Resum del joc",
    "play_by_play": "Joc per joc",
    "your_clips": "Els teus clips"
  },
  "locales": {
    "en": "English",
    "de": "German",
    "es": "Spanish",
    "pt": "Portugal",
    "uk-UA": "Ukrainian",
    "pl": "Polish",
    "fr": "French",
    "he": "Hebrew",
    "it": "Italian",
    "ca": "Catalan",
    "ko": "Korean",
    "zh": "Chinese",
    "bs": "Lokalni",
    "title": "Trieu l'idioma del lloc web"
  },
  "not-found": "No trobat",
  "pages": {
    "account": {
      "account_delete_button": "Esborrar compte",
      "account_delete_confirm": "Esteu segur que voleu suprimir el vostre perfil?",
      "account_delete_description": "Aquesta acció no es pot revertir.",
      "tab_billing_title": "Informació de facturació",
      "tab_devices_title": "Dispositius",
      "tab_general_title": "General",
      "tab_password_title": "Contrasenya",
      "tab_ppv_access_text": "El bitllet PPV dóna accés a",
      "tab_ppv_no_ppv": "No tens bitllets de PPV. \nAneu a la pàgina de l'esdeveniment per comprar una entrada.",
      "tab_ppv_title": "Entrades PPV",
      "tab_subscription_title": "Subscripció",
      "account_delete_successfully": "El vostre compte s'ha suprimit correctament!",
      "account_update_successfully": "El vostre compte s'ha actualitzat correctament!"
    },
    "account_billing": {
      "alert_credit_card_save_success": "La informació de la vostra targeta de crèdit s'ha desat correctament",
      "alert_credit_card_update_success": "La informació de la vostra targeta de crèdit s'ha actualitzat correctament",
      "button_back_to_billing_info": "Torna a la informació de facturació",
      "card_cvv": "CVC / CVV",
      "card_expiry_date": "Data de caducitat",
      "card_holder_name": "Nom a la targeta",
      "card_number": "Número de targeta",
      "label_modal_confirm_credit_card_delete": "Un cop retireu la vostra targeta de crèdit, la vostra subscripció també es cancel·larà. \nEl contingut encara està disponible fins a la data de finalització del pla. \nEsteu segur que voleu eliminar la targeta de crèdit?",
      "payment_history_empty_text": "El vostre historial de pagaments és buit",
      "payment_history_title": "Historial de pagaments",
      "alert_credit_card_delete_success": "La vostra targeta de facturació s'ha suprimit correctament",
      "payment_in_progress": "Pagament en curs",
      "payment_in_progress_message": "No tanqueu aquesta pàgina fins que no s'hagi processat el vostre pagament."
    },
    "account_password": {
      "alert_password_update_success": "La teva contrasenya s'ha canviat correctament"
    },
    "account_subscription": {
      "active_subscription": "Subscripció activa",
      "alert_access_code_redeem_code_used": "El codi d'accés sol·licitat ja s'ha utilitzat",
      "alert_access_code_redeem_not_found": "El codi d'accés sol·licitat no existeix",
      "alert_access_code_redeemed_success": "El codi d'accés s'ha bescanviat correctament",
      "alert_subscription_cancel_success": "La subscripció s'ha cancel·lat correctament",
      "button_back_to_my_subscription": "Torna a la meva subscripció",
      "button_back_to_my_tickets": "Torno als meus bitllets",
      "button_subscribe_now": "Subscriu-te ara",
      "button_subscription_cancel": "Cancel·la la subscripció",
      "button_subscription_keep": "Mantenir la subscripció",
      "cancel_subscription_modal_subtitle": "La vostra subscripció es cancel·larà al final del vostre període de facturació el {date}. \nPodeu canviar d'opinió en qualsevol moment abans d'aquesta data.",
      "cancel_subscription_modal_title": "Esteu segur que voleu cancel·lar la vostra subscripció?",
      "label_full_access": "Accés complet",
      "label_full_access_description": "Accés complet a tot el contingut del lloc web",
      "label_limited_access": "Accés limitat",
      "label_limited_access_description": "Accés limitat a tot el contingut del lloc web",
      "label_plan_amount": "Import del pla",
      "label_taxes": "Impostos",
      "label_total_billed": "Total facturat",
      "list_subscriptions_empty": "La llista de subscripcions disponibles és buida. \nTorna després",
      "no_subscriptions_text": "No hi ha subscripcions actives a la llista. \nTorna després",
      "or_select_plan": "o selecciona el teu pla",
      "payment_details": "{title}: detalls de pagament",
      "redeem_access_code_label": "Bescanvia el teu codi d'accés aquí",
      "redeem_access_code_title": "Bescanvia el teu codi d'accés",
      "subscription_due_date": "Data de venciment",
      "subscription_expiration_date": "Caducat per",
      "subscription_expires": "La subscripció caduca el {date}",
      "subscription_last_payment_date": "Data de l'últim pagament",
      "subscription_next_charge_date": "Següent càrrec | \nPròxima data de càrrega",
      "tab_all_subscriptions": "Totes les subscripcions",
      "tab_my_subscriptions": "Les meves subscripcions",
      "checkbox_apply_as_new_credit_card": "Desa els detalls de la targeta",
      "renew_subscription_expires": "La subscripció és recurrent. \nPròxima data de càrrega {date}"
    },
    "auth_login": {
      "device_no_longer_allowed_error": "El vostre dispositiu actual ja no és a la llista de dispositius permesos. \nSi us plau, torneu a iniciar sessió."
    },
    "auth_login_limit": {
      "current_device": "Dispositiu actual",
      "device_limit_reached": "S'ha arribat al límit de dispositius",
      "remove_device_to_login": "Si us plau, elimineu-ne un per iniciar sessió"
    },
    "auth_password": {
      "back_to_sign_in": "Torna per iniciar la sessió",
      "forgot_password_help_text": "T'ajudarem a restablir-la",
      "send_reset_link": "Envia l'enllaç de restabliment",
      "set_new_password": "Estableix una nova contrasenya",
      "forgot_password_success_text": "S'ha enviat un correu electrònic amb instruccions sobre com restablir la contrasenya a {email}. \nSi no rebeu el correu electrònic en uns minuts, feu clic al botó Torna a enviar les instruccions a continuació.",
      "resend_instruction": "Torna a enviar les instruccions"
    },
    "auth_sign_in": {
      "auth_terms": "En continuar, acceptes els nostres {terms_of_service} i la {privacy_policy}",
      "new_to": "Nou a",
      "welcome_message": "Benvingut de nou",
      "sign_in_with_provider": "Inicieu la sessió amb les vostres credencials de {provider}"
    },
    "auth_sign_up": {
      "already_have_account": "Ja tens un compte?",
      "enter_your_details_below": "Introduïu les vostres dades a continuació",
      "follow_category_header": "Seleccioneu les categories que voleu seguir",
      "password_updated_successuly_text": "La teva contrasenya s'ha actualitzat correctament",
      "back_to_all_teams": "Torna a tots els equips",
      "follow_teams_header": "Seleccioneu els equips que voleu seguir",
      "back_to_all_categories": "Torna a totes les categories"
    },
    "clips": {
      "title_no_clips": "Encara no tens cap clip. \nVés al vídeo i crea el teu primer clip!",
      "title_no_highlights": "Encara no tens cap destacat. \nVés al vídeo i crea els teus primers moments destacats!",
      "title_no_items_with_selected_filter": "No s'han trobat elements amb aquest filtre"
    },
    "clubs": {
      "manage_following": "Gestiona el seguiment",
      "choose_your_club": "Tria el teu club",
      "choose_your_club_message": "Tria el club amb el qual t'associaràs"
    },
    "events": {
      "appreciation": "Gràcies!",
      "event_start_date": "L'esdeveniment començarà el {date}",
      "failed_already_used_code": "Aquest codi ja s'ha utilitzat",
      "failed_code_applied_message": "Torneu-ho a provar o introduïu un altre codi.",
      "failed_code_applied_title": "Codi invàlid",
      "failed_purchased_message": "Torneu-ho a provar o introduïu una altra targeta.",
      "failed_statistics": "No s'han pogut carregar les estadístiques relacionades amb aquest esdeveniment. Torneu-ho a provar més tard.",
      "modal_share_title": "Comparteix aquest vídeo",
      "modal_share_with_time": "Comença a les {time}",
      "purchased_declined": "S'ha rebutjat el pagament",
      "submit_reedem_code_input_placeholder": "Introduïu el vostre codi d'accés aquí",
      "success_code_applied": "El codi {title} s'ha aplicat correctament",
      "success_purchased": "Has comprat correctament {subscription}",
      "tabs": {
        "event_tags": {
          "choose_player_color": "Introdueix un jugador '",
          "choose_tag": "Trieu el tipus d'etiqueta",
          "create_tag": "Crea una etiqueta",
          "filters": {
            "by_player_number": "Jugador '",
            "by_type": "Per tipus",
            "clear_all_btn": "Esborra-ho tot | \nEsborra-ho tot ({count}) | \nEsborra-ho tot ({count})",
            "no_tags": "No hi ha cap etiqueta que coincideixi amb el filtre seleccionat",
            "popup_title": "Filtre",
            "search_input_placeholder": "Cerca etiquetes específiques",
            "show_results_btn": "Mostra els resultats",
            "select_all": "Selecciona-ho tot | \nSeleccioneu-ho tot ({count}) | \nSelecciona-ho tot ({count})",
            "by_team": "Per equip"
          },
          "is_external_content": "Aquest és contingut extern, no podeu crear una etiqueta",
          "make_tag_global": "Feu que l'etiqueta sigui global",
          "name_the_tag": "Anomena l'etiqueta",
          "tag_creating_successfully_text": "L'etiqueta s'ha creat correctament",
          "tag_deleting_successfully_text": "L'etiqueta s'ha suprimit correctament",
          "tag_updating_successfully_text": "L'etiqueta s'ha actualitzat correctament",
          "title_enabled_tagging": "No hi ha etiquetes a la llista",
          "title_no_tags_not_logged_in": "Encara no hi ha etiquetes a la llista, si us plau, inicieu sessió per crear etiquetes noves",
          "update_tag": "Actualitza l'etiqueta",
          "title_no_tags": "Encara no s'ha creat cap etiqueta",
          "pause_all_btn": "Posa-ho tot en pausa",
          "play_all_btn": "Juga-ho tot",
          "choose_team": "Trieu un equip"
        },
        "event_videos": {
          "clip_creating_successfully_text": "El vostre clip estarà llest aviat",
          "create_clip": "Crea un clip",
          "name_the_clip": "Anomena el clip",
          "title_enabled_clipping": "No hi ha cap clip a la llista",
          "title_no_clips_not_logged_in": "Encara no hi ha cap clip a la llista, si us plau, inicieu sessió per crear nous clips",
          "title_no_clips": "Encara no s'ha creat cap clip",
          "clip_deleted_successfully_text": "El clip s'ha suprimit correctament",
          "rename_clip": "Canvia el nom del clip",
          "clip_updating_successfully_text": "El clip s'ha actualitzat correctament"
        },
        "event_stats": {
          "empty_stats_data": "Les dades d'estadístiques estan buides",
          "game_board_title": "Estadístiques del joc",
          "score_board_title": "Puntuació"
        }
      },
      "viewing_conditions": "Trieu les vostres condicions de visualització",
      "watching_event_by_text": "Estàs veient aquest esdeveniment per",
      "event_will_start_in_a_few_min": "L'esdeveniment començarà automàticament en uns minuts...",
      "player_errors": {},
      "game_video_login_message": "Inicia la sessió per veure els vídeos del joc",
      "tags_login_message": "Inicieu la sessió per veure les etiquetes",
      "success_purchased_secondary": "La teva compra ha estat correcta",
      "time_till_kick_off": "Temps per començar:",
      "failed_purchased_message_2": "Torneu-ho a provar o utilitzeu un altre mètode de pagament."
    },
    "league": {
      "all_rounds": "Totes les rondes"
    },
    "videos": {
      "description_no_items": "Aquest és el lloc per als teus vídeos preferits. \nGuarda, revisa i comparteix!",
      "last_viewed": "Darrera visita",
      "title_no_items": "Afegeix alguns vídeos als seleccionats",
      "user_interesting": "Potser us interessa"
    },
    "club": {
      "favorite_club_creating_successfully_text": "El vostre club ha estat escollit amb èxit"
    },
    "categories": {
      "no_following_category_for_logged_user": "De moment no esteu seguint res. \nFes clic {here} per estar al dia dels teus esdeveniments preferits",
      "no_following_category_for_unlogged_user": "{login} per estar al dia dels vostres esdeveniments preferits"
    }
  },
  "sport_types": {
    "americanFootball": "Futbol americà",
    "beachvolleyball": "Voleibol de platja",
    "fieldhockey": "Hoquei sobre gespa",
    "icehockey": "Hoquei sobre gel",
    "rollerhockey": "Hoquei patins",
    "badminton": "Bàdminton",
    "baseball": "Beisbol",
    "basketball": "Bàsquet",
    "bowling": "Bowling",
    "cricket": "Cricket",
    "floorball": "Floorball",
    "futsal": "Futbol sala",
    "gymnastics": "Gimnàstica",
    "handball": "Handbol",
    "lacrosse": "Lacrosse",
    "netball": "Netball",
    "other": "Altres",
    "rugby": "Rugbi",
    "snooker": "Snooker",
    "soccer": "Futbol",
    "softball": "Softbol",
    "swimming": "Natació",
    "tennis": "Tennis",
    "volleyball": "Voleibol",
    "waterpolo": "Waterpolo",
    "wrestling": "Lluita",
    "football": "Futbol"
  },
  "errors": {
    "expired_session_error": "La teva sessió ha caducat. \nSi us plau, torneu a iniciar sessió.",
    "vod_files_not_found": "Vaja! \nNo s'han trobat fitxers de vídeo",
    "vod_playback_failed": "Vaja! \nLa reproducció ha fallat durant l'emissió de VOD",
    "could_not_find_event": "Ho sentim, no hem pogut trobar l'esdeveniment que estàs buscant.",
    "could_not_find_page": "Ho sentim, no hem pogut trobar la pàgina que cerques.",
    "could_not_find_team": "Ho sentim, no hem pogut trobar l'equip que busques.",
    "event_not_found": "No s'ha trobat l'esdeveniment",
    "page_not_found": "Pàgina no trobada",
    "team_not_found": "No s'ha trobat l'equip",
    "could_not_find_results": "Ho sentim, no hem trobat cap resultat per a aquesta cerca.",
    "ERR400CL11": "No s'ha pogut crear el clip",
    "ERR400CM11": "El pagament ha fallat",
    "ERR400CM12": "No s'ha pogut fer la comanda",
    "ERR400CM13": "Ha fallat el pagament de captura",
    "ERR400CM14": "La subscripció ha fallat desar la informació del comerciant",
    "ERR400CM15": "Comproveu la informació de la vostra targeta de crèdit",
    "ERR400CM16": "S'ha proporcionat un valor de plataforma no vàlid",
    "ERR400GN00": "Petició dolenta",
    "ERR400GN11": "Identificació proporcionada no vàlida",
    "ERR400GN12": "S'han proporcionat camps no actualitzables",
    "ERR400ID11": "S'ha proporcionat una identitat no vàlida",
    "ERR400ID12": "S'ha proporcionat un element d'identitat no vàlid",
    "ERR400ID13": "La identitat no es pot afegir a la llista del club",
    "ERR400ID14": "S'ha proporcionat un parell d'elements d'identitat i identitat no vàlid",
    "ERR400TG11": "No s'ha pogut crear l'etiqueta",
    "ERR400UR11": "El nom i els cognoms del perfil són obligatoris",
    "ERR400UR12": "El correu electrònic és obligatori",
    "ERR400UR13": "L'usuari ja té una targeta de crèdit emmagatzemada",
    "ERR400UR14": "Si us plau, empleneu els camps de nom, cognoms i correu electrònic del vostre perfil abans de desar una targeta de crèdit",
    "ERR400UR17": "L'usuari no té cap targeta de crèdit per editar",
    "ERR400UR18": "La subscripció ja està cancel·lada",
    "ERR400UR19": "La subscripció no està activa",
    "ERR400UR20": "El nom d'usuari i la contrasenya són necessaris",
    "ERR400UR21": "El compte no està actiu",
    "ERR401AT11": "Es requereix un testimoni",
    "ERR401AT12": "El testimoni de restabliment de la contrasenya no és vàlid",
    "ERR401AT13": "El testimoni d'actualització no és vàlid",
    "ERR401AT14": "Emissor de tema desconegut",
    "ERR401AT15": "Tipus de testimoni d'assumpte no compatible",
    "ERR401AT16": "Token invàlid",
    "ERR401AT17": "Token caducat",
    "ERR401AT18": "No s'ha pogut obtenir un correu electrònic del testimoni",
    "ERR401AT19": "Usuari no trobat",
    "ERR401GN00": "No autoritzat",
    "ERR401UR11": "El nom d'usuari o la contrasenya són incorrectes. \nSi us plau, comproveu les vostres credencials.",
    "ERR402GN00": "Pagament obligatori",
    "ERR403GN00": "Prohibit",
    "ERR403TG11": "L'usuari no pot actualitzar les etiquetes globals",
    "ERR403TG12": "L'usuari no pot crear etiquetes globals",
    "ERR403TG13": "L'usuari no pot eliminar les etiquetes que no hagi estat creada per un",
    "ERR403TG14": "L'usuari no pot suprimir les etiquetes globals",
    "ERR404CM11": "No es poden obtenir subscripcions",
    "ERR404CM12": "No s'ha trobat el codi d'accés",
    "ERR404EV11": "No es pot obtenir la informació de l'esdeveniment",
    "ERR404EV12": "No s'ha trobat l'esdeveniment o VOD",
    "ERR404GN00": "No trobat",
    "ERR404ID00": "No s'ha trobat la identitat",
    "ERR404ID11": "No s'ha pogut recuperar la identitat",
    "ERR404PR00": "No s'ha trobat el producte",
    "ERR404PR11": "No es pot obtenir informació ppv",
    "ERR404TG00": "No s'ha trobat l'etiqueta",
    "ERR404TM00": "No s'ha trobat l'equip",
    "ERR404UR00": "Usuari no trobat",
    "ERR404UR11": "No s'ha trobat l'ordre",
    "ERR404UR12": "No s'ha trobat la subscripció",
    "ERR404UR13": "L'usuari no existeix",
    "ERR404UR14": "El perfil no existeix",
    "ERR404UR15": "No s'ha trobat la targeta per a aquest usuari",
    "ERR404UR16": "No s'ha trobat el vídeo a la llista de seguiment",
    "ERR404VD11": "Vod no s'ha pogut recuperar",
    "ERR409CM11": "El codi d'accés ja està en ús",
    "ERR409GN00": "Conflicte",
    "ERR409PR12": "Producte ja comprat",
    "ERR409UR11": "El club ja està afegit",
    "ERR409UR12": "Usuari ja registrat",
    "ERR422GN00": "Contingut no processable",
    "ERR451GN00": "No disponible per motius legals",
    "ERR500GN00": "error del servidor intern",
    "ERR500NT12": "No s'ha pogut enviar el correu electrònic de restabliment de la contrasenya",
    "ERR501GN00": "No implementat",
    "ERR502GN00": "Porta d'enllaç incorrecta",
    "ERR503GN00": "Servei no disponible",
    "ERR504GN00": "Temps d'espera de la passarel·la",
    "could_not_find_league": "Ho sentim, no hem pogut trobar la lliga que cerques.",
    "default_error_message": "S'ha produït un error del servidor. \nSiusplau, intenta-ho més tard",
    "device_limit_exceeded": "S'ha superat el límit de dispositius!",
    "league_not_found": "No s'ha trobat la lliga",
    "vod_corrupted_files": "No s'ha pogut carregar el vídeo",
    "passwords_dont_match": "Les contrasenyes no coincideixen",
    "incomplete_cvc": "El codi de seguretat de la teva targeta està incomplet!",
    "incomplete_expiry": "La data de caducitat de la teva targeta està incompleta!",
    "incomplete_number": "El vostre número de targeta està incomplet!",
    "invalid_expiry_month_past": "L'any de caducitat de la vostra targeta ha passat!",
    "invalid_expiry_year": "L'any de caducitat de la vostra targeta no és vàlid!",
    "invalid_expiry_year_past": "L'any de caducitat de la vostra targeta ha passat!",
    "invalid_number": "El vostre número de targeta no és vàlid!",
    "popup_closed": "La finestra emergent s'ha tancat",
    "login_failed": "Error a l'iniciar sessió",
    "ERR400UR24": "La contrasenya antiga és incorrecta. \nSi us plau, comproveu la vostra credencial"
  },
  "tags": {
    "baseball_bottom_1st": "1r inferior",
    "baseball_bottom_2nd": "2n baix",
    "baseball_double": "doble",
    "baseball_double_play": "doble joc",
    "baseball_fly_out": "sortir volant",
    "baseball_ground_out": "terra fora",
    "baseball_hit": "colpejar",
    "baseball_home_run": "home run",
    "baseball_popout": "aparèixer",
    "baseball_run": "correr",
    "baseball_single": "solter",
    "baseball_stolen_base": "base robada",
    "baseball_strikeout": "ratllar",
    "baseball_substitution": "substitució",
    "baseball_top_1st": "1r superior",
    "baseball_top_2nd": "2n superior",
    "baseball_triple_play": "triple play",
    "baseball_tripple": "triple",
    "baseball_walk": "caminar",
    "basketball_alley_oop": "carreró oop",
    "basketball_assist": "assistir",
    "basketball_big_play": "gran jugada",
    "basketball_block": "bloc",
    "basketball_dunk": "mata",
    "basketball_momentym": "impuls",
    "basketball_money_time": "temps de diners",
    "basketball_substitution": "substitució",
    "basketball_three_point": "tres punts",
    "beach_volleyball_dig": "cavar",
    "beach_volleyball_double": "doble",
    "beach_volleyball_free_ball": "pilota lliure",
    "beach_volleyball_match_point": "punt de partit",
    "beach_volleyball_mishit": "merda",
    "beach_volleyball_serve": "servir",
    "beach_volleyball_set_point": "punt d'ajust",
    "beach_volleyball_shank": "canya",
    "beach_volleyball_side_out": "costat cap a fora",
    "beach_volleyball_spike": "Espiga",
    "beach_volleyball_substitution": "substitució",
    "field_ball_loss": "pèrdua de pilota",
    "field_ball_possesion": "possessió de pilota",
    "field_circle_penetration": "penetració del cercle",
    "field_hockey_4v4": "4v4",
    "field_hockey_big_hit": "gran cop",
    "field_hockey_fight": "lluitar",
    "field_hockey_goal": "objectiu",
    "field_hockey_other": "altres",
    "field_hockey_power_play": "joc de poder",
    "field_hockey_save": "guardar",
    "field_hockey_shot": "tir",
    "field_hockey_substitution": "substitució",
    "field_interception": "intercepció",
    "field_penalty_corner": "córner de penal",
    "field_press_fp_hc": "premsa",
    "field_time_penalty": "penalització de temps",
    "field_turn_over": "donar la volta",
    "football_big_catch": "gran captura",
    "football_big_hit": "gran cop",
    "football_big_run": "gran cursa",
    "football_field_goal": "gol de camp",
    "football_kick_return": "tornada de puntada",
    "football_kickoff": "inici",
    "football_punt_return": "punt tornada",
    "football_substitution": "substitució",
    "football_touch_down": "tocar avall",
    "futsal_corner_kick": "tir de córner",
    "futsal_foul": "falta",
    "futsal_free_kick": "tir lliure",
    "futsal_goal": "objectiu",
    "futsal_header": "capçalera",
    "futsal_penalty": "penalització",
    "futsal_redcard": "targeta vermella",
    "futsal_save": "guardar",
    "futsal_shot": "tir",
    "futsal_shot_on_target": "disparat a porteria",
    "futsal_substitution": "substitució",
    "futsal_yellowcard": "targeta groga",
    "general_bad": "dolent",
    "general_drill": "trepant",
    "general_good": "bo",
    "gymnastic_wow": "vaja",
    "handball_foul": "falta",
    "handball_goal": "objectiu",
    "handball_penalty_shot": "tir de penal",
    "handball_red_card": "targeta vermella",
    "handball_save": "guardar",
    "handball_shot_on_goal": "rematat a porteria",
    "handball_substitution": "substitució",
    "handball_suspension": "suspensió",
    "handball_yellow_card": "targeta groga",
    "hockey_4v4": "4v4",
    "hockey_big_hit": "gran cop",
    "hockey_fight": "lluitar",
    "hockey_goal": "objectiu",
    "hockey_power_play": "joc de poder",
    "hockey_save": "guardar",
    "hockey_shot": "tir",
    "hockey_substitution": "substitució",
    "lacrosse_clear": "clar",
    "lacrosse_face_off": "cara a cara",
    "lacrosse_goal": "objectiu",
    "lacrosse_ground_ball": "pilota de terra",
    "lacrosse_man_up": "home amunt",
    "lacrosse_penalty": "penalització",
    "lacrosse_save": "guardar",
    "lacrosse_shot": "tir",
    "lacrosse_substitution": "substitució",
    "other_wow": "altre uau",
    "roller_ball_loss": "pèrdua de pilota",
    "roller_ball_possesion": "possessió de pilota",
    "roller_circle_penetration": "penetració del cercle",
    "roller_hockey_4v4": "4v4",
    "roller_hockey_big_hit": "gran cop",
    "roller_hockey_fight": "lluitar",
    "roller_hockey_goal": "objectiu",
    "roller_hockey_other": "altres",
    "roller_hockey_power_play": "joc de poder",
    "roller_hockey_save": "guardar",
    "roller_hockey_shot": "tir",
    "roller_hockey_substitution": "substitució",
    "roller_interception": "intercepció",
    "roller_penalty_corner": "cantonada",
    "roller_press_fp_hc": "premsa",
    "roller_time_penalty": "penalització de temps",
    "roller_turn_over": "donar la volta",
    "rugby_break_down": "desglossament",
    "rugby_conversion": "conversió",
    "rugby_drop_goal": "caure objectiu",
    "rugby_garry_owen": "garry owen",
    "rugby_grubber": "borrador",
    "rugby_knock_on": "trucar a",
    "rugby_maul": "apallissar",
    "rugby_penalty_try": "assaig de penal",
    "rugby_substitution": "substitució",
    "soccer_corner_kick": "tir de córner",
    "soccer_foul": "falta",
    "soccer_free_kick": "tir lliure",
    "soccer_goal": "objectiu",
    "soccer_header": "capçalera",
    "soccer_penalty": "penalització",
    "soccer_redcard": "targeta vermella",
    "soccer_save": "guardar",
    "soccer_shot": "tir",
    "soccer_shot_on_target": "disparat a porteria",
    "soccer_substitution": "substitució",
    "soccer_yellowcard": "targeta groga",
    "volleyball_dig": "cavar",
    "volleyball_double": "doble",
    "volleyball_free_ball": "pilota lliure",
    "volleyball_match_point": "punt de partit",
    "volleyball_mishit": "merda",
    "volleyball_serve": "servir",
    "volleyball_set_point": "punt d'ajust",
    "volleyball_shank": "canya",
    "volleyball_side_out": "costat cap a fora",
    "volleyball_spike": "Espiga",
    "volleyball_substitution": "substitució",
    "wrestling_wow": "vaja"
  }
}
